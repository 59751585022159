import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { requestMonimalzProducts } from '../actions/shopActions';

import Layout from '../components/layout/layout';
import { Footer, pageWithNavigation } from '../components/common';
import Checkout from '../components/checkout/index';

class CheckoutBasketPage extends Component {
  componentDidMount() {
    const { requestMonimalzProducts } = this.props;

    requestMonimalzProducts();
  }

  render() {
    const { data } = this.props;
    return (
      <Layout
        title={data.wordings.fr.home.page_title}
        description={data.wordings.fr.home.description}
      >
        <Checkout page="CHECKOUT_BASKET" />
        <Footer />
      </Layout>
    );
  }
}

CheckoutBasketPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export const query = graphql`
  query CheckoutBasketQuery {
    wordings {
      fr {
        home {
          page_title
          description
        }
      }
    }
  }
`;

export default connect(
  null,
  { requestMonimalzProducts }
)(pageWithNavigation(CheckoutBasketPage));
